"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { createWaitingList } from "@flow/api";
import type { InsertUserWaitingList } from "@flow/db/schemas";
import { insertUserWaitingListSchema } from "@flow/db/schemas";
import { Button } from "@flow/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@flow/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@flow/ui/form";
import { Input } from "@flow/ui/input";
import { H3 } from "@flow/ui/typography";

import { ParticlesComp } from "./particles";

export default function WaitingList() {
  const [dialogState, setDialogState] = useState(false);
  const form = useForm<InsertUserWaitingList>({
    resolver: zodResolver(insertUserWaitingListSchema),
  });

  const onSubmit = async (data: InsertUserWaitingList) => {
    await createWaitingList({ data })
      .then(() => {
        setDialogState(true);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <Dialog onOpenChange={setDialogState} open={dialogState}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-center">
              <H3>Welcome</H3>
            </DialogTitle>
            <DialogDescription className="text-center">
              We have recieved your request for an invite and will reach out as
              soon as possible with an invitation.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="text-center">
            <div className="mx-auto">
              <Button variant="secondary" onClick={() => setDialogState(false)}>
                Close
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      {dialogState && <ParticlesComp />}
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex grow flex-row space-x-4"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field, fieldState }) => (
              <FormItem className="w-full">
                <FormControl>
                  <div>
                    <Input
                      className={fieldState.error ? "shake" : ""}
                      placeholder="Signup for waiting list"
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Signup</Button>
        </form>
      </Form>
    </>
  );
}
