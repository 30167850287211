import { cn } from "@flow/ui";

function H1({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <h1
      className={cn(
        "scroll-m-20 font-extrabold text-4xl tracking-tight lg:text-5xl",
        className,
      )}
      {...props}
    />
  );
}

function H2({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <h2
      className={cn(
        "scroll-m-20 border-b pb-2 font-semibold text-3xl tracking-tight first:mt-0",
        className,
      )}
      {...props}
    />
  );
}

function H3({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <h3
      className={cn(
        "scroll-m-20 font-medium text-2xl tracking-tight",
        className,
      )}
      {...props}
    />
  );
}

function Lead({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <p className={cn("text-muted-foreground text-xl", className)} {...props} />
  );
}

function Muted({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <p className={cn("text-muted-foreground text-sm", className)} {...props} />
  );
}

function Paragraph({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return <p className={cn("leading-7", className)} {...props} />;
}

export { H1, H2, H3, Lead, Muted, Paragraph };
